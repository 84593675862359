import React from 'react'
import { Link } from 'gatsby'

import { Layout, SEO } from '../components/common'
// import { MetaData } from '../components/common/meta'

const CreditPage = () => (
    <React.Fragment>
        <SEO title='Credit' description='How this website was made' />
        <Layout>
            <div className="container">
                <article className="content">
                    <h1 className="content-title">Site Credit</h1>

                    <h4>Tech</h4>
                    <ul>
                        <li>Framework: <a href="https://www.gatsbyjs.com/">Gatsby</a></li>
                        <li>Hosting: <a href="https://www.netlify.com/">Netlify</a></li>
                        <li>Data Vis: <a href="https://observablehq.com/">ObservableHQ</a></li>
                    </ul>

                    <h4>Design</h4>
                    <ul>
                        <li>Homepage Banner: <a href="https://www.svgbackgrounds.com/">Visiwig</a></li>
                        <li>Unsplash Images: <a href="https://unsplash.com/photos/pwpVGQ-A5qI">@markusspiske</a>, <a href="https://unsplash.com/photos/8SjeH5pZbjw">@ansleycreative</a></li>
                    </ul>

                    <h4>ObservableHQ Components</h4>
                    <ul>
                        <li>Table: <a href="https://observablehq.com/@tmcw/tables">@tmcw</a></li>
                        <li>Inputs: <a href="https://observablehq.com/@jashkenas/inputs">@jashkenas</a></li>
                        <li>Vega Charts: <a href="https://observablehq.com/@vega/vega-lite-api">@vega</a></li>
                    </ul>

                </article>
            </div>
        </Layout>
    </React.Fragment>
)

export default CreditPage
